<template>
	<v-dialog
		:value="dialog"
		max-width="500"
		persistent scrollable
	>
		<v-card>
			<v-card-actions class="grey lighten-4 justify-space-between py-4">
				<div class="px22">
					{{ formTitle }}
				</div>
				<v-btn icon @click="close()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-actions>

			<v-card-text class="py-6"
				style="height: 70vh;"
			>
				<v-container fluid>
					<v-row class="ma-0 pa-0">
						<v-col
							cols="12"
						>
							<staff-select
								v-model="user"
								:errors="formErrors"
								:disabled="(detail !== null)"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<time-picker
								v-model="editedItem.clock_in"
								label="Clock in"
								name="clock_in"
								icon="mdi-clock-in"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.clock_in_location_user_input"
								label="Clock in location user input"
								name="clock_in_location_user_input"
								icon="mdi-map"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<time-picker
								v-model="editedItem.clock_out"
								label="Clock out"
								name="clock_out"
								icon="mdi-clock-out"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.clock_out_location_user_input"
								label="Clock out location user input"
								name="clock_out_location_user_input"
								icon="mdi-map"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-area
								v-model="editedItem.remarks"
								label="Remarks"
								name="remarks"
								icon="mdi-message-outline"
								:errors="formErrors"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions class="grey lighten-4">
				<v-spacer/>
				<v-btn
					color="error darken-1"
					text rounded
					@click="close"
				>
					Cancel
				</v-btn>
				<v-btn
					:loading="saving"
					color="blue darken-1"
					text rounded
					@click="save"
				>
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import TableDialogMixin from "@/mixins/TableDialogMixin.js";
import FetchStaffs from "@/mixins/FetchStaffs.js";

export default {
	name: "AttendanceCreateUpdateDialog",
	mixins: [TableDialogMixin, FetchStaffs],
	data: () => ({
		model: "attendance",
		dateToFormat: [],
		timeToFormat: ["clock_in", "clock_out"],
		requiredFields: ["user"],
		editedItem: {
			user: null,
			date: null,
			clock_in: null,
			clock_out: null,
			clock_in_location_user_input: null,
			clock_in_location: null,
			clock_out_location_user_input: null,
			clock_out_location: null,
			remarks: null,
			working_hour: null,
		},
		user: null,
	}),
	watch: {
		user(v) {
			this.editedItem.user = v
		}
	},
	computed: {
		...mapGetters({
			detail: "attendance/inEdit",
			formErrors: "attendance/formErrors",
		}),
		formTitle() {
			if (this.detail) return "Edit Attendance"
			else return "New Attendance"
		},
		today() {
			return this.$moment(new Date()).format("YYYY-MM-DD")
		},
		getRequestData() {
			return {
				user: this.user,
				date: this.today,
				clock_in: this.editedItem.clock_in
					? `${this.today}T${this.editedItem.clock_in}`
					: null,
				clock_out: this.editedItem.clock_out
					? `${this.today}T${this.editedItem.clock_out}`
					: null,
				clock_in_location_user_input: this.editedItem.clock_in_location_user_input,
				clock_in_location: this.editedItem.clock_in_location,
				clock_out_location_user_input: this.editedItem.clock_out_location_user_input,
				clock_out_location: this.editedItem.clock_out_location,
				remarks: this.editedItem.remarks,
				working_hour: this.editedItem.working_hour,
			}
		},
		getCreateFormData() {
			return this.getRequestData
		},
		getEditFormData() {
			return this.getRequestData
		}
	},
	methods: {
		...mapMutations("attendance", ["SET_FOR_EDIT", "SET_DIALOG_STATE", "SET_FORM_ERRORS"]),
	}
}
</script>

<style scoped lang="scss">
@import "src/styles/fonts.scss";
</style>
